import axios from "axios";
import { httpClient, operationHttpClient } from "./HttpInterceptor";


const LocationAPIKey = "ed65b3c09ee0490e828123548231606";

const commonService = {
  GetProfileTypeByWalletAddress: async (token: any, data: any) =>
    await httpClient.post("/api/common/getProfileTypeByWalletAddress", data),

  SaveWalletRoleType: async (token: any, data: any) =>
    await httpClient.post("/api/common/saveUniqueWalletRole", data),

  GetBuyToken: async (data: any) =>
    await operationHttpClient.post("/buy-token", data),

  GetApproveUSDC: async (data: any) =>
    await operationHttpClient.post("/approve-usdc", data),

  GetApproveDAI: async (data: any) =>
    await operationHttpClient.post("/approve-dai", data),

  GetApproveFARX: async (data: any) =>
    await operationHttpClient.post("/approve-frax", data),

  GetApproveCandidate: async (data: any) =>
    await operationHttpClient.post("/add-candidate", data),

  GetCmcInr: async (data: any) =>
    await operationHttpClient.get(
      `${process.env.NEXT_PUBLIC_COIN_GECKO_API_URL}`,
      {
        params: data ? data : void 0,
      }
    ),

  GetSummaryList: async (data: any) =>
    await operationHttpClient.get("/get-list", {
      params: data ? data : void 0,
    }),
  GetLocationByCoordinates: async (latitude: number, longitude: number) =>
    await axios.get(`https://api.weatherapi.com/v1/forecast.json?key=${LocationAPIKey}&q=${latitude},${longitude}&days=1&aqi=no&alerts=no`),
  fetchCurrentUser: async () => {
    try {
      let myRes = await httpClient.get("/api/me",
        {
          params: void 0,
        }
      );
      return myRes.data;
    } catch (error) {
      console.log(error);
    }
  }
};

export default commonService;
