import { useDisclosure } from "@chakra-ui/react";
import { createContext, FC, ReactNode } from "react";

interface Context {
  pendingRequestModal: ReturnType<typeof useDisclosure>;
  profileRequestModal: ReturnType<typeof useDisclosure>;
  editCompanyAboutModal: ReturnType<typeof useDisclosure>;
  editCompanyDetailModal: ReturnType<typeof useDisclosure>;
  requestAcceptModal: ReturnType<typeof useDisclosure>;
  requestRejectModal: ReturnType<typeof useDisclosure>;
  editSocialProfileModal: ReturnType<typeof useDisclosure>;
  editEmployeeAboutModal: ReturnType<typeof useDisclosure>;
  editEducationModal: ReturnType<typeof useDisclosure>;
  editExperienceModal: ReturnType<typeof useDisclosure>;
  editSkillModal: ReturnType<typeof useDisclosure>;
  disputeRequestModal: ReturnType<typeof useDisclosure>;
  disputeChatDrawer: ReturnType<typeof useDisclosure>;
  verifyAdharOTPModal: ReturnType<typeof useDisclosure>;
  profileViewModal: ReturnType<typeof useDisclosure>;
  jobPostingModal: ReturnType<typeof useDisclosure>;
  viewJobPostingModal: ReturnType<typeof useDisclosure>;
  jobApplicationsModal: ReturnType<typeof useDisclosure>;
  jobApplyModal: ReturnType<typeof useDisclosure>;
  confirmWalletModal: ReturnType<typeof useDisclosure>;
  platformUserModal: ReturnType<typeof useDisclosure>;
  viewPlatformUserModal: ReturnType<typeof useDisclosure>;
  platformOrganizationModal: ReturnType<typeof useDisclosure>;
  viewPlatformOrganizationModal: ReturnType<typeof useDisclosure>;
  experienceDocumentModal: ReturnType<typeof useDisclosure>;
  experienceApproveModal: ReturnType<typeof useDisclosure>;
  experienceViewDocumentModal: ReturnType<typeof useDisclosure>;
  nestedCreditConverterModal: ReturnType<typeof useDisclosure>;
  platformOrgPlanSettingModal: ReturnType<typeof useDisclosure>;
  platformOrganizationConfirmationModal: ReturnType<typeof useDisclosure>;
  confirmDialogModal: ReturnType<typeof useDisclosure>;
  experienceApproverEmailModal: ReturnType<typeof useDisclosure>;
  forgotPasswordModal: ReturnType<typeof useDisclosure>;
  walletConnectModal: ReturnType<typeof useDisclosure>;
  consentModal: ReturnType<typeof useDisclosure>;
  infoSharingConsentModal: ReturnType<typeof useDisclosure>;
  identityVerificationModal: ReturnType<typeof useDisclosure>;
  changeProfilePasswordModal: ReturnType<typeof useDisclosure>;
  locationRegionModal: ReturnType<typeof useDisclosure>;
  walletLoginDrawer: ReturnType<typeof useDisclosure>;
  addLanguageModal: ReturnType<typeof useDisclosure>;
  addPayModal: ReturnType<typeof useDisclosure>;
  updateProfileEmailModal: ReturnType<typeof useDisclosure>;
  addWorkScheduleModal: ReturnType<typeof useDisclosure>;
  loginModal: ReturnType<typeof useDisclosure>;
}

const ModalContext = createContext<Context>({} as Context);

interface ModalProviderProps {
  children: any;
}

const ModalProvider: FC<ModalProviderProps> = (props) => {
  const pendingRequestModal = useDisclosure();
  const profileRequestModal = useDisclosure();
  const editCompanyAboutModal = useDisclosure();
  const editCompanyDetailModal = useDisclosure();
  const requestRejectModal = useDisclosure();
  const requestAcceptModal = useDisclosure();
  const editEmployeeAboutModal = useDisclosure();
  const editSocialProfileModal = useDisclosure();
  const editEducationModal = useDisclosure();
  const editExperienceModal = useDisclosure();
  const editSkillModal = useDisclosure();
  const disputeRequestModal = useDisclosure();
  const disputeChatDrawer = useDisclosure();
  const verifyAdharOTPModal = useDisclosure();
  const profileViewModal = useDisclosure();
  const jobPostingModal = useDisclosure();
  const viewJobPostingModal = useDisclosure();
  const jobApplicationsModal = useDisclosure();
  const jobApplyModal = useDisclosure();
  const confirmWalletModal = useDisclosure();
  const platformUserModal = useDisclosure();
  const viewPlatformUserModal = useDisclosure();
  const platformOrganizationModal = useDisclosure();
  const viewPlatformOrganizationModal = useDisclosure();
  const experienceDocumentModal = useDisclosure();
  const experienceApproveModal = useDisclosure();
  const experienceViewDocumentModal = useDisclosure();
  const nestedCreditConverterModal = useDisclosure();
  const platformOrgPlanSettingModal = useDisclosure();
  const platformOrganizationConfirmationModal = useDisclosure();
  const confirmDialogModal = useDisclosure();
  const experienceApproverEmailModal = useDisclosure();
  const forgotPasswordModal = useDisclosure();
  const walletConnectModal = useDisclosure();
  const consentModal = useDisclosure();
  const identityVerificationModal = useDisclosure();
  const changeProfilePasswordModal = useDisclosure();
  const locationRegionModal = useDisclosure();
  const walletLoginDrawer = useDisclosure();
  const addLanguageModal = useDisclosure();
  const addPayModal = useDisclosure();
  const updateProfileEmailModal = useDisclosure();
  const addWorkScheduleModal = useDisclosure();
  const loginModal = useDisclosure();
  const infoSharingConsentModal = useDisclosure();

  return (
    <ModalContext.Provider
      value={{
        pendingRequestModal,
        profileRequestModal,
        editCompanyAboutModal,
        editCompanyDetailModal,
        requestAcceptModal,
        requestRejectModal,
        editEmployeeAboutModal,
        editSocialProfileModal,
        editEducationModal,
        editExperienceModal,
        editSkillModal,
        disputeRequestModal,
        disputeChatDrawer,
        verifyAdharOTPModal,
        profileViewModal,
        jobPostingModal,
        viewJobPostingModal,
        jobApplicationsModal,
        jobApplyModal,
        confirmWalletModal,
        platformUserModal,
        viewPlatformUserModal,
        platformOrganizationModal,
        viewPlatformOrganizationModal,
        experienceDocumentModal,
        experienceApproveModal,
        experienceViewDocumentModal,
        nestedCreditConverterModal,
        platformOrgPlanSettingModal,
        platformOrganizationConfirmationModal,
        confirmDialogModal,
        experienceApproverEmailModal,
        forgotPasswordModal,
        walletConnectModal,
        consentModal,
        identityVerificationModal,
        changeProfilePasswordModal,
        locationRegionModal,
        walletLoginDrawer,
        addLanguageModal,
        addPayModal,
        updateProfileEmailModal,
        addWorkScheduleModal,
        loginModal,
        infoSharingConsentModal
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
