import { useState, useEffect, useRef } from 'react';
import commonService from '@/components/services/CommonService';
import { useRouter } from 'next/router';
import AuthUser from 'types/AuthUser';

const useUserDetails = (): {
  user: AuthUser | null;
  isLoading: boolean;
  error: string | null;
  triggerEffect: () => void;
} => {
  const [userDetails, setUserDetails] = useState<AuthUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const router = useRouter();
  const forceUpdateRef = useRef<boolean>(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        let currentUser = await commonService.fetchCurrentUser();
        if (currentUser) {
          
          setUserDetails(currentUser);
          setIsLoading(false);
        } else {
          setError('Error fetching user details');
          setIsLoading(false);
        }
      } catch (error) {
        setError('Error fetching user details');
        setIsLoading(false);
      }
    };

    if (forceUpdateRef.current || router.asPath) {
      // If forced update or route change, fetch user details
      fetchUserDetails();
      forceUpdateRef.current = false; // Reset force update flag
    }
  }, [router.asPath, forceUpdateRef]);

  const triggerEffect = () => {
    forceUpdateRef.current = true;
  };

  return { user: userDetails, isLoading, error, triggerEffect };
};

export default useUserDetails;
