let isLoaded = false;
if (typeof window !== 'undefined') {
    // Perform localStorage action
    isLoaded = true;
}
const LocalStorageService = (function () {

    var _service: Storage;
    const _getService = () => {
        if (!_service) {
            //_service = this;
            return _service
        }
        return _service
    }

    function _setCurrentAddress(address: any) {
        localStorage.setItem('currentAddress', address);
    }

    function _getCurrentAddress() {
        return isLoaded ? localStorage.getItem('currentAddress') : null;
    }

    function _clearCurrentAddress() {
        localStorage.removeItem('currentAddress');
    }

    function _setCandidateId(candidateId: any) {
        localStorage.setItem('candidateId', candidateId);
    }

    function _getCandidateId() {
        return isLoaded ? localStorage.getItem('candidateId') : null;
    }

    function _setCandidateDetails(candidateDetail: any) {
        localStorage.setItem('candidateDetail', JSON.stringify(candidateDetail));
    }

    function _getCandidateDetails() {

        var profileDetail = isLoaded ? localStorage.getItem('candidateDetail') : null;
        if (profileDetail) {
            return JSON.parse(profileDetail);
        }
        else {
            return null;
        }

    }

    function _clearCandidateDetails() {
        localStorage.removeItem('candidateId');
        localStorage.removeItem('candidateDetail');
    }

    function _setOrganizationDetails(orgDetail: any) {
        localStorage.setItem('organizationDetails', JSON.stringify(orgDetail));
    }

    function _getOrganizationDetails() {

        var orgProfile = isLoaded ? localStorage.getItem('organizationDetails') : null;
        if (orgProfile) {
            return JSON.parse(orgProfile);
        }
        else {
            return null;
        }

    }
    function _clearOrganizationDetails() {
        localStorage.removeItem('organizationDetails');
    }

    function _setProfileRequestDetails(candidateDetail: any) {
        localStorage.setItem('profileRequestDetail', JSON.stringify(candidateDetail));
    }

    function _getProfileRequestDetails() {

        var profileDetail = isLoaded ? localStorage.getItem('profileRequestDetail') : null;
        if (profileDetail) {
            return JSON.parse(profileDetail);
        }
        else {
            return null;
        }

    }

    function _clearProfileRequestDetails() {
        localStorage.removeItem('profileRequestDetail');
    }

    function _setPlatformUserId(platformUserId: any) {
        localStorage.setItem('platformUserId', platformUserId);
    }

    function _getPlatformUserId() {
        return isLoaded ? localStorage.getItem('platformUserId') : null;
    }

    function _setPlatformUserDetails(orgDetail: any) {
        localStorage.setItem('platformUserDetails', JSON.stringify(orgDetail));
    }

    function _getPlatformUserDetails() {

        var orgProfile = isLoaded ? localStorage.getItem('platformUserDetails') : null;
        if (orgProfile) {
            return JSON.parse(orgProfile);
        }
        else {
            return null;
        }

    }
    function _clearPlatformUserDetails() {
        localStorage.removeItem('platformUserDetails');
        localStorage.removeItem('platformUserId');
    }

    function _setUserWalletRoleDetails(detail: any) {
        localStorage.setItem('userWalletRoleDetails', JSON.stringify(detail));
    }

    function _getUserWalletRoleDetails() {

        var detail = isLoaded ? localStorage.getItem('userWalletRoleDetails') : null;
        if (detail) {
            return JSON.parse(detail);
        }
        else {
            return null;
        }

    }
    function _clearUserWalletRoleDetails() {
        localStorage.removeItem('userWalletRoleDetails');
    }

    return {
        getService: _getService,
        //Current Address
        setCurrentAddress: _setCurrentAddress,
        getCurrentAddress: _getCurrentAddress,
        clearCurrentAddress: _clearCurrentAddress,
        //Candidate Profile
        setCandidateId: _setCandidateId,
        getCandidateId: _getCandidateId,
        setCandidateDetails: _setCandidateDetails,
        getCandidateDetails: _getCandidateDetails,
        clearCandidateDetails: _clearCandidateDetails,
        //Organization Profile
        setOrganizationDetails: _setOrganizationDetails,
        getOrganizationDetails: _getOrganizationDetails,
        clearOrganizationDetails: _clearOrganizationDetails,
        //ProfileRequestDetail
        setProfileRequestDetails: _setProfileRequestDetails,
        getProfileRequestDetails: _getProfileRequestDetails,
        clearProfileRequestDetails: _clearProfileRequestDetails,
        //Platform User Profile (All)
        setPlatformUserId: _setPlatformUserId,
        getPlatformUserId: _getPlatformUserId,
        setPlatformUserDetails: _setPlatformUserDetails,
        getPlatformUserDetails: _getPlatformUserDetails,
        clearPlatformUserDetails: _clearPlatformUserDetails,
        //User Wallet Role Details
        setUserWalletRoleDetails: _setUserWalletRoleDetails,
        getUserWalletRoleDetails: _getUserWalletRoleDetails,
        clearUserWalletRoleDetails: _clearUserWalletRoleDetails,
    }
})();

export default LocalStorageService;