import { configureChains, Connector, createClient } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { localhost, polygon, polygonMumbai } from "@wagmi/chains";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { TorusConnector } from "@toruslabs/torus-wagmi-connector";

export const { chains, provider, webSocketProvider } = configureChains(
  [polygonMumbai, polygon, localhost],
  [
    jsonRpcProvider({
      rpc: (chain: any) => ({
        http: `${polygon.rpcUrls}`,
      }),
    }),
  ]
);

export const wagmiClient = createClient({
  connectors: [
    new TorusConnector({
      chains: chains as any,
      options: {
        chainId: polygonMumbai.id,
        host: polygonMumbai.rpcUrls.toString(),
      },
    }) as any,
    new MetaMaskConnector({
      chains,
      options: {
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
  autoConnect: true,
});
