import { extendTheme } from "@chakra-ui/react";

import "@fontsource/montserrat/100.css";
import "@fontsource/metropolis/400.css";

import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

import "@fontsource/mulish/200.css";
import "@fontsource/mulish/400.css";

const theme = extendTheme({
  colors: {
    primary: "#264252",
    secondary: "#0AB1BC",
    tertiary: "#ECFFB6",
    error: "#F44E4E",
    success: "#37BE6D",
  },
  fonts: {
    body: "Montserrat",
    heading: "Montserrat",
    metropolis: "metropolis",
    mulish: "mulish",
  },
});

export default theme;
