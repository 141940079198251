import VestingScheduleDialogBox from "../components/VestingScheduleBox/VestingScheduleDialogBox";

const public_routes = [
  {
    name: "About Us",
    href: "/about-us",
    // isMenu: false,
    // index: 21,
    // menuName: "About Us",
  },
  {
    name: "Help",
    href: "/help",
    // isMenu: false,
    // index: 22,
    // menuName: "Help",
  },
  {
    name: "Contact Us",
    href: "/contact-us",
    // isMenu: false,
    // index: 23,
    // menuName: "Contact Us",
  },
  {
    name: "FAQ",
    href: "/faq",
  },
  {
    name: "Terms Of Service",
    href: "/legal/terms",
  },
  {
    name: "Disclaimers",
    href: "/legal/disclaimer",
  },
  {
    name: "End User License Agreement",
    href: "/legal/end-user-license-agreement",
  },
  {
    name: "Acceptable Use Policy",
    href: "/legal/acceptable-use-policy",
  },
  {
    name: "User Agreement",
    href: "/legal/user-agreement",
  },
  {
    name: "Privacy Policy",
    href: "/privacy/policy",
  },
  {
    name: "Cookies Policy",
    href: "/privacy/policy/cookies",
  },
  {
    name: "Copyright Policy",
    href: "/privacy/policy/copyright",
  }
];
const company_routes = [
  {
    name: "Profile",
    href: "/profile/company",
    isMenu: false,
    index: 0,
  },
  {
    name: "Find Employee",
    href: "/find-employee",
    isMenu: true,
    index: 3,
    menuName: "Employees",
  },
  {
    name: "Post Job",
    href: "/jobs/post-job",
    isMenu: false,
    index: 2,
    menuName: "Job Posting",
  },
  {
    name: "Requests",
    href: "/requests/company",
    isMenu: true,
    index: 1,
    menuName: "Manage Requests",
  },
  {
    name: "Disputes",
    href: "/disputes",
    isMenu: true,
    index: 1,
    menuName: "Manage Requests",
  },
  {
    name: "User Management",
    href: "/user-management",
    isMenu: true,
    index: 3,
    menuName: "Employees",
  },
  // {
  //   name: "Application Settings",
  //   href: "/app-setting",
  //   isMenu: false,
  //   index: 4,
  //   menuName: "Application Settings",
  // },
];
const employee_routes = [
  {
    name: "Home",
    href: "/dashboard/employee",
    isLink: true
  },
  {
    name: "My Profile",
    href: "/profile/employee",
    isLink: true
  },
  {
    name: "Profile Requests",
    href: "/requests/employee",
    isLink: true
  },
  {
    name: "Find Jobs",
    href: "/jobs/search-job",
    isLink: true
  },
  {
    name: "Help",
    href: "/faq",
  },
  //Todo: Until token implementation is complete
  // {
  //   name: "My Account",
  //   href: "/buy",
  //   isLink:true
  // },
];

const account_routes = [
  {
    path: "/buy",
    label: "Buy",
  },
  {
    path: "/swap",
    label: "Swap",
  },
  {
    path: "/history",
    label: "History",
  },
  {
    path: "/vesting",
    label: "Vesting"
  }
];

// Application Admin Role Routes
const admin_routes = [
  {
    name: "Platform Support Users",
    href: "/platform-user/support",
  },
  {
    name: "Application Settings",
    href: "/app-setting",
  },
];

// Application Platform Manager Role Routes
const platform_manager_routes = [
  {
    name: "Organization List",
    href: "/platform-user/manager/organization",
  },
  {
    name: "Candidate List",
    href: "/platform-user/manager/candidate",
  },
];

// Application Platform Support User Role Routes
const platform_support_routes = [
  {
    name: "Candidate List",
    href: "/platform-user/candidate",
  },
  {
    name: "Organization List",
    href: "/platform-user/organization",
  },
];

const common_routes = [
  {
    name: "Employee Registration",
    href: "/register/employee",
  },
  {
    name: "Candidate Registration",
    href: "/register/candidate",
  },
  {
    name: "Candidate Complete Profile",
    href: "/complete/candidate",
  },
  {
    name: "Candidate Profile Recover",
    href: "/recover",
  },
  {
    name: "Candidate Verify Profile",
    href: "/verify-account",
  },
  {
    name: "Organization Registration",
    href: "/register/company",
  },
  {
    name: "Find Employee By User",
    href: "/find-employee/[id]",
  },
  {
    name: "Find Job Applicants",
    href: "/jobs/post-job/[applicantId]",
  },
  {
    name: "Organization Candidate",
    href: "/requests/company/add-employee",
  },
  {
    name: "Home",
    href: "/",
  },
  // Organization Wallet Connection
  {
    name: "Organization Wallet Login",
    href: "/login-wallet",
  },
  // Admin and Platform Login
  {
    name: "Login",
    href: "/login/platform-login",
  },
  //Candidate RouteGuard Routing - My Profile || KYC || My referral || Recruiter
  {
    name: "My Profile",
    href: "/profile/employee",
  },
  {
    name: "My Referral",
    href: "/referral/employee",
  },
  {
    name: "KYC",
    href: "/kyc",
  },
  {
    name: "Recruiter",
    href: "/profile/recruiter"
  },
  //Candidate - Account Settings
  {
    name: "Account Settings",
    href: "/account/settings/employee",
  },
  //Blockchain Routing
  {
    name: "SWAP",
    href: "/swap",
  },
  {
    name: "History",
    href: "/history",
  },
  {
    name: "Vesting",
    href: "/vesting",
  },
  //Nested Logins
  {
    name: "Nested Login",
    href: "/login",
  },
  //Delete Profile
  {
    name: "Delete Profile",
    href: "/delete-profile"
  }
];

const slug_routes = [

  {
    name: "Find Employee By User",
    href: "/find-employee/",
  },
  {
    name: "Find Job Applicants",
    href: "/jobs/post-job/",
  },
];

const homePath = {
  root: '/',
  login: '/login',
  candidateHome: '/dashboard/employee',
  orgHome: '/profile/company',
  adminHome: '/platform-user/support',
  supportHome: '/platform-user/candidate',
  supportManagerHome: '/platform-user/manager/organization'
}

const footerPaths = [
  {
    name: "Terms Of Service",
    href: "/legal/terms",
  },
  {
    name: "Disclaimers",
    href: "/legal/disclaimer",
  },
  {
    name: "End User License Agreement",
    href: "/legal/end-user-license-agreement",
  },
  {
    name: "Acceptable Use Policy",
    href: "/legal/acceptable-use-policy",
  },
  {
    name: "User Agreement",
    href: "/legal/user-agreement",
  },
  {
    name: "Privacy Policy",
    href: "/privacy/policy",
  },
  {
    name: "Cookies Policy",
    href: "/privacy/policy/cookies",
  },
  {
    name: "Copyright Policy",
    href: "/privacy/policy/copyright",
  }
]

export {
  public_routes,
  company_routes,
  employee_routes,
  common_routes,
  admin_routes,
  platform_manager_routes,
  platform_support_routes,
  account_routes,
  homePath,
  footerPaths
};
