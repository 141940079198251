import "../styles/globals.css";
import Head from "next/head";
import { ChakraProvider } from "@chakra-ui/react";
import { EmotionCache } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import theme from "../constants/theme";
import { ModalProvider } from "../context/ModalContext";
import { AppPropsWithLayout } from "../types/Page";
import createEmotionCache from "../styles/createEmotionCache";
import { AppProps } from "next/app";
import { WagmiConfig } from "wagmi";
import { wagmiClient, chains } from "../core/utility/wagmiClient";
import { Router } from "next/router";
import { useEffect, useMemo } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { RouteGuard } from "../core/route-guard/RouteGuard";
import { SWRConfig } from "swr";
import fetchJson from "lib/fetchJson";
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// Google analytics Reference::
// https://ravinduinduwara.medium.com/adding-google-analytics-to-a-react-typescript-next-js-application-5d114ed8b170
const isProd = process.env.NEXT_PUBLIC_NODE_ENV === "production";

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: any) => page);

  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => NProgress.done();
    const handleRouteChange = (url: any) => {
      if (isProd) {
        window.gtag("config", process.env.NEXT_PUBLIC_GA_ID as string, {
          page_path: url,
        });
      }
    };

    Router.events.on("routeChangeStart", handleRouteStart);
    Router.events.on("routeChangeComplete", handleRouteDone);
    Router.events.on("routeChangeError", handleRouteDone);
    Router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      // Make sure to remove the event handler on unmount!
      Router.events.off("routeChangeStart", handleRouteStart);
      Router.events.off("routeChangeComplete", handleRouteDone);
      Router.events.off("routeChangeError", handleRouteDone);
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  const swrConfig = useMemo(() => {
    return {
      fetcher: fetchJson,
      onError: (err: any) => {
        console.error(err);
      },
    };
  }, []);

  return (
    <>
      <Head>
        <title>Nested - The Decentralized Talent Network</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <WagmiConfig client={wagmiClient}>
        <RouteGuard>
          <CacheProvider value={clientSideEmotionCache}>
            <SWRConfig value={swrConfig}>
              <ChakraProvider theme={theme}>
                <ModalProvider>
                  {getLayout(<Component {...pageProps} />)}
                </ModalProvider>
              </ChakraProvider>
            </SWRConfig>
          </CacheProvider>
        </RouteGuard>
      </WagmiConfig>
    </>
  );
}

export default MyApp;
