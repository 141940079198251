const experiences = [
  {
    image: "/images/exp1.png",
    title: "Sr. UX Designer",
    date: "Jul 2015 - Jan 2021 · 6 yrs 9 mos",
    description:
      "I have worked on more than 300+ application designs for mobile and website. I have experience on working on many different..... see more",
    verified: true,
    institution: "ABK Private Limited",
    dispute: 0,
  },
  {
    image: "/images/exp2.png",
    title: "Sr. UX Designer",
    date: "Jul 2015 - Jan 2021 · 6 yrs 9 mos",
    description:
      "I have worked on more than 300+ application designs for mobile and website. I have experience on working on many different..... see more",
    verified: false,
    institution: "AX Design Studio",
    dispute: 0,
  },
  {
    image: "/images/exp3.png",
    title: "Sr. UX Designer",
    date: "Jul 2015 - Jan 2021 · 6 yrs 9 mos",
    description:
      "I have worked on more than 300+ application designs for mobile and website. I have experience on working on many different..... see more",
    institution: "Karel Information Technology",
    verified: true,
    dispute: 1,
  },
];

const publicProfile = {
  firstName: "",
  middleName: "",
  lastName: "",
  // skills: string[];
  profilePic: "",
};

const privateProfile = {
  dateOfBirth: "",
  mobileNumber: "",
  email: "",
  panNumber: "",
  aadharHash: "",
  aadharMask: "",
  address: "",
}

const socialProfile = {
  linkedin: "https://www.linkedin.com/",
  github: "https://www.github.com/",
  instagram: "https://www.instagram.com/",
  discord: "https://www.discord.com/",
  twitter: "https://www.twitter.com/",
  medium: "https://www.medium.com/",
}

const education = [
  {
    image: "/images/edu1.png",
    institute: "RK University",
    degree: "Master of Computer Applications (MCA)",
    date: "Jul 2015 - Mar 2017",
  },
  {
    image: "/images/edu2.png",
    institute: "Indian Institute Of Management–Ahmedabad (IIM–Ahmedabad)",
    degree: "MBA",
    date: "Jul 2015 - Mar 2017",
  },
];

const skills1 = ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"];
const skills: any[] = [
  // { value: 'Graphic Design', label: 'Graphic Design' },
  // { value: 'UI/UX', label: 'UI/UX' },
  // { value: 'Motion Design', label: 'Motion Design' },
  // { value: 'Logo Design', label: 'Logo Design' },
];

const employees = [
  {
    name: "Amit Trivedi",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 6,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp1.png",
    verified: true,
    canView: false,
  },
  {
    name: "Amit Trivedi",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 4,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp2.png",
    verified: false,
    canView: true,
  },
  {
    name: "Amit Trivedi",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 3,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp3.png",
    verified: false,
    canView: false,
  },
  {
    name: "Amit Trivedi",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 1,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp4.png",
    verified: false,
    canView: false,
  },
];

const employees2 = [
  {
    name: "Amit Trivedi",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 5,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp1.png",
    verified: true,
    canView: true,
  },
  {
    name: "Darlene Robertson",
    skills: ["Motion Design", "Logo Design"],
    experience: 6,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp2.png",
    verified: true,
    canView: true,
  },
  {
    name: "Esther Howard",
    skills: ["Graphic Design", "UI/UX"],
    experience: 4,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp3.png",
    verified: true,
    canView: false,
  },
  {
    name: "Courtney Henry",
    skills: ["UI/UX", "Motion Design", "Logo Design"],
    experience: 3,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp4.png",
    verified: true,
    canView: false,
  },
];

const requests = [
  {
    name: "Amit Trivedi",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    panNumber: "A123456789",
    experience: 6,
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp1.png",
    date: "Jul 2015 - Mar 2017",
    verified: true,
  },
  {
    name: "Darlene Robertson",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 7,
    panNumber: "C123456789",
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp2.png",
    date: "Jul 2015 - Mar 2020",
    verified: true,
  },
  {
    name: "Esther Howard",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 2,
    panNumber: "B123456789",
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp3.png",
    date: "Jul 2015 - Mar 2019",
    verified: false,
  },
  {
    name: "Courtney Henry",
    skills: ["Graphic Design", "UI/UX", "Motion Design", "Logo Design"],
    experience: 4,
    panNumber: "A123456789",
    institute: "Sr. UX Designer at ABK Private Limited",
    image: "/images/emp4.png",
    date: "Jul 2015 - Mar 2018",
    verified: false,
  },
];

const disputes = [
  {
    id: "DIS89329381",
    date: "30 March 2021 2:00PM",
    employee: employees[0],
    resolved: false,
  },
  {
    id: "DIS89329382",
    date: "30 March 2021 2:00PM",
    employee: employees[0],
    resolved: false,
  },
  {
    id: "DIS89329383",
    date: "30 March 2021 2:00PM",
    employee: employees[0],
    resolved: false,
  },
  {
    id: "DIS89329384",
    date: "30 March 2021 2:00PM",
    employee: employees[0],
    resolved: true,
  },
  {
    id: "DIS89329385",
    date: "30 March 2021 2:00PM",
    employee: employees[0],
    resolved: true,
  },
  {
    id: "DIS89329386",
    date: "30 March 2021 2:00PM",
    employee: employees[0],
    resolved: true,
  },
];

const disputeChat = [
  {
    id: "DIS89329381",
    time: "4min ago",
    sender: employees[0],
    message: `Hello HR, 
    My Experiance is incorrect here please correct it from your end. here i attached experiance letter for your futher investigation.
    
    Thank you`,
    attachment: {
      name: "Experiance_letter.docx",
    },
  },
  {
    id: "DIS89329381",
    time: "2min ago",
    sender: {
      ...employees[1],
      name: "You",
    },
    message: `Hello HR, 
    My Experiance is incorrect here please correct it from your end. here i attached experiance letter for your futher investigation.
    
    Thank you`,
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const employementType = [
  "Permanent",
  "Contract",
];

const employementJobLocation = [
  "On Site",
  "Remotely"
];

const employementJobType = [
  "One-Time",
  "Complex",
  "Application",
];

const dataType = [
  "Int",
  "String",
];

const countries = [
  "India",
];

const genders = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'N', label: 'Prefer not to say' }
];

const platformUserRole = [
  { value: '4', label: 'Support User' },
  { value: '5', label: 'Manager' },
];

const KYCNameFormat = [
  { value: '1', label: 'First Name - Last Name' },
  { value: '2', label: 'Last Name - First Name' },
  { value: '3', label: 'First Name - Middle Name - Last Name' },
  { value: '4', label: 'First Name - Last Name - Middle Name' },
  { value: '5', label: 'First Name - Middle Name' },
  { value: '6', label: 'Middle Name - First Name' },
  { value: '7', label: 'Last Name - First Name - Middle Name' },
  { value: '8', label: 'First Name' },
];

const entriesPerPageOption = [
  10,
  20,
  30,
  40,
  50
];

enum platformType {
  Nested = "nested",
  EXTERNAL = "external"
}

const jobPlatforms = [
  { value: platformType.Nested, label: 'NESTED' },
  { value: platformType.EXTERNAL, label: 'EXTERNAL' },
];

const proficiencyOption = [
  "Beginner",
  "Expert",
  "Fluent",
  "Intermediate",
  "Native"
];

const payPeriodOption = [
  "per hour",
  "per week",
  "per day",
  "per month",
  "per year"
];

const years = [
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
  "2013",
  "2012",
  "2011",
  "2010",
  "2009",
  "2008",
  "2007",
  "2006",
  "2005",
  "2004",
  "2003",
  "2002",
  "2001",
  "2000",
  "1999",
  "1998",
  "1997",
  "1996",
  "1995",
  "1994",
  "1993",
  "1992",
  "1991",
  "1990",
];

const companyRequests = [
  {
    name: "Tata Pvt. Ltd.",
    image: "/images/comp1.png",
    message:
      "Hello Amit, We are looing for UI/UX designer please allow your profile access so we can move ahead with futher steps",
    date: "9 April 2022 9:00 AM",
    permissions: { idCard: true },
  },
  {
    name: "Reliance Pvt. Ltd.",
    image: "/images/comp2.png",
    message:
      "Hello Amit, We are looing for UI/UX designer please allow your profile access so we can move ahead with futher steps",
    date: "9 April 2022 9:00 AM",
    permissions: { idCard: true, personalDetail: true },
  },
  {
    name: "Aditya Birla Capital",
    image: "/images/comp3.png",
    message:
      "Hello Amit, We are looing for UI/UX designer please allow your profile access so we can move ahead with futher steps",
    date: "9 April 2022 9:00 AM",
    permissions: { idCard: true, personalDetail: true },
  },
];

const notifications = [
  {
    notification: "Profile View Request",
    time: "2min ago",
    sender: employees[0],
    read: false,
  },
  {
    notification: "Profile View Request",
    time: "2min ago",
    sender: employees[1],
    read: false,
  },
  {
    notification: "Profile View Request",
    time: "2min ago",
    sender: employees[2],
    read: true,
  },
  {
    notification: "Profile View Request",
    time: "2min ago",
    sender: employees[3],
    read: true,
  },
  {
    notification: "Profile View Request",
    time: "2min ago",
    sender: employees[0],
    read: true,
  },
];

const appAuthSettings = {
  isMiddlewareEnabled: true
}

enum transactionTypeSettings {
  CandidatePublicProfile = "Candidate PublicProfile",
  CandidateConfirmation = "Candidate Confirmation",
  ProfileApproval = "Profile Approval",
  ProfileViewRequest = "Profile View Request",
  JobApply = "Job Apply",
  RaiseDispute = "Raise Dispute",
  CandidateReferral = "Candidate Referral",
  CandidatePrivateProfile = "Candidate PrivateProfile",
  CandidateSocialProfile = "Candidate SocialProfile",
  CandidateEducationDetails = "Candidate EducationDetails",
  CandidateExperienceDetails = "Candidate ExperienceDetails",

  CandidateLogin = "Candidate Login",
  CandidateRegistration = "Candidate Registration",
  CandidateExternalJobApply = "Candidate External Job Apply",
}

enum orgTransactionTypeSettings {
  ProfileApproval = "Profile Approval",
  ProfileViewRequest = "Profile View Request",
  JobPosting = "Job Posting",
}

enum userRoles {
  Organization = 1,
  Candidate = 2,
  Admin = 3,
  PlatformSupport = 4,
  PlatformManager = 5
}

enum jobTabStatus {
  AllJobs = 0,
  SavedJobs = 1,
  AppliedJobs = 2
}

enum experienceStatus {
  NotSent = 0,
  RequestSent = 1,
  Approved = 2,
  Rejected = 3
}

export {
  months,
  years,
  experiences,
  education,
  skills,
  employees,
  employees2,
  requests,
  disputes,
  disputeChat,
  companyRequests,
  notifications,
  employementType,
  employementJobLocation,
  employementJobType,
  publicProfile,
  privateProfile,
  socialProfile,
  countries,
  dataType,
  genders,
  appAuthSettings,
  platformUserRole,
  transactionTypeSettings,
  orgTransactionTypeSettings,
  KYCNameFormat,
  userRoles,
  entriesPerPageOption,
  jobTabStatus,
  experienceStatus,
  jobPlatforms,
  proficiencyOption,
  platformType,
  payPeriodOption
};
