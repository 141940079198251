import { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';
import {
    account_routes,
    admin_routes,
    common_routes,
    company_routes,
    employee_routes,
    platform_manager_routes,
    platform_support_routes,
    public_routes,
    homePath
} from 'data/routes';
import LocalStorageService from '../service/LocalStorageService';
import _ from 'lodash';
import useUser from 'hooks/useUser';
import AuthUser from 'types/AuthUser';
import useUserDetails from 'hooks/useUserDetails';

export { RouteGuard };

function RouteGuard({ children }: any) {
    const router = useRouter();
    const { user } = useUser({
        redirectTo: '/login',
        redirectIfFound: true,
    });
    const [authorized, setAuthorized] = useState(false);
    const [currentPath, setCurrentPath] = useState('');
    const { address } = useAccount();

    const { user: currentUser } = useUserDetails();
    const handleRouteChange = useCallback((path: string) => {
        authCheck(path);
    }, []);

    const loginRedirectPath = "/login";

    const pathsToSkipLoginRedirect = [
        "/recover/",
        "/verify-account/",
        "/register/candidate?referral=",
        "/register/candidate",
        "/delete-profile"
    ];

    const jobSlugRoutes = [
        "/jobs/search-job",
        "/jobs/view/",
    ];

    const orgAdditionalRoutes = [
        "/find-employee/",
        "/jobs/post-job/",
        ...jobSlugRoutes
    ];

    useEffect(() => {
        const setRouting = async () => {
            if (currentUser && currentUser?.isLoggedIn && currentUser?.userId && currentUser?.userId > 0) {

                let authProfileDetails = currentUser as AuthUser;
                setAuthorized(true);
                let roleType = currentUser?.roleId ?? 0;
                const path = router.asPath.split('?')[0];
                switch (roleType) {
                    case 3:
                    case 4:
                    case 5:
                        LocalStorageService.clearUserWalletRoleDetails();
                        LocalStorageService.clearPlatformUserDetails();
                        const platform_routes = public_routes.map(d => {
                            return {
                                name: d.name,
                                href: d.href
                            };
                        }).concat(admin_routes, platform_support_routes, platform_manager_routes);
                        if (path == homePath.root || path == homePath.login) {
                            router.push(roleType == 3 ? homePath.adminHome :
                                roleType == 4 ? homePath.supportHome :
                                    homePath.supportManagerHome);
                        }
                        else if (!platform_routes.some(x => x.href.includes(path))) {
                            router.push({
                                pathname: homePath.root,
                                query: { returnUrl: router.asPath }
                            });
                        }

                        break;
                    case 2:
                        if (authProfileDetails.isRegistered) {
                            const candidate_routes = public_routes.map(d => {
                                return {
                                    name: d.name,
                                    href: d.href
                                };
                            }).concat(employee_routes, common_routes);
                            if (path == homePath.root || path == homePath.login) {
                                router.push(homePath.candidateHome);
                            }
                            else if (path.startsWith('/jobs/') && jobSlugRoutes.some(jobPath => path.includes(jobPath))) {
                            }
                            else if (!candidate_routes.some(x => x.href.includes(path))) {
                                router.push({
                                    pathname: homePath.root,
                                    query: { returnUrl: router.asPath }
                                });
                            }

                        } else {
                            router.push("/register/employee");
                        }
                        break;
                    case 1:
                        if (authProfileDetails.isRegistered) {
                            const organization_routes = public_routes.map(d => {
                                return {
                                    name: d.name,
                                    href: d.href
                                };
                            }).concat(company_routes.map(d => {
                                return {
                                    name: d.name,
                                    href: d.href
                                };
                            }), common_routes);
                            if (path == homePath.root || path == homePath.login) {
                                router.push(homePath.orgHome);
                            }
                            else if (organization_routes.some(x => x.href.includes(path)) ||
                                orgAdditionalRoutes.some(route => path.includes(route))) {
                                //router.push(router.asPath);
                            }
                            else {
                                router.push({
                                    pathname: '/',
                                    query: { returnUrl: router.asPath }
                                });
                            }

                        }

                        break;
                    default:
                        if (!currentUser && router.asPath.includes("register/company")) {
                            //allow company to register from link directly
                            router.push(router.asPath);
                        }
                        else if (!address && router.asPath.includes("login")) {
                            //allow platform users to login from link directly
                            //If no wallet is connected remain on login page
                            router.push(router.asPath);
                        } else if (!currentUser) {
                            // if wallet connected and wallet address doesn't exit- goto create profile
                            router.push(homePath.root);
                        }
                }
            }
            else if (public_routes.some(x => x.href.includes(router.asPath))) {
                setAuthorized(true);
            }
            else if (router.asPath.startsWith('/jobs/') && jobSlugRoutes.some(path => router.asPath.includes(path))) {
                setAuthorized(true);
            }
            else if (currentUser && !currentUser?.isLoggedIn) {
                setAuthorized(true);
                if (!pathsToSkipLoginRedirect.some(path => router.asPath.includes(path))) {
                    router.push(loginRedirectPath);
                }
            }
            else if ((address && (address == "0x" || currentUser?.address !== address)) || address == undefined) {
                setAuthorized(true);
            }
            else {
                setAuthorized(false);
            }
        }
        setRouting();
    }, [currentUser, router.asPath])

    useEffect(() => {
        authCheck(router.asPath);
        // on route change start - hide page content by setting authorized to false
        router.events.on('routeChangeStart', handleRouteChange);
        // on route change complete - run auth check
        router.events.on('routeChangeComplete', handleRouteChange);
        // unsubscribe from events in useEffect return function
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [handleRouteChange]);

    async function authCheck(url: string) {
        // redirect to login page if accessing a private page and not logged in
        setCurrentPath(router.asPath);
        const c_routes = company_routes.map(d => {
            return {
                name: d.name,
                href: d.href
            };
        });

        const routes = public_routes.map(d => {
            return {
                name: d.name,
                href: d.href
            };
        }).concat(c_routes, employee_routes, common_routes, admin_routes, platform_support_routes, platform_manager_routes);

        const path = url.split('?')[0];
        if (!authorized && path !== "/" && !(!path.includes("/register/candidate?referral=") || path.includes("/recover/") || path.includes("/find-employee/") || path.includes("/jobs/post-job/")) && !routes.some(x => x.href.includes(path))) {
            setAuthorized(false);

            router.push({
                pathname: '/',
                query: { returnUrl: router.asPath }
            });
        }

    }

    return (authorized && children);
}
