import axios, { AxiosInstance, AxiosResponse } from "axios";
import { appAuthSettings } from "../../data/mock";

const NESTED_API_KEY = process.env.NESTED_API_KEY || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJuZXN0ZWQtYXBpIiwiaXNzIjoibmVzdGVkLmNsdWIiLCJpYXQiOjE3MDc4Mjk0NDMsIm5hbWUiOiJOZXN0ZWQifQ.jvnOGB7frfJ4rluHTSqG3t5prCSAaZl1UmXQ-odD8-M";

let API_BASE_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

if (typeof window !== "undefined") {
  // Client-side-only code
  API_BASE_URL = window.location.origin;
}

// Nested api client
const httpClient: AxiosInstance = axios.create({
  baseURL: API_BASE_URL
});

httpClient.interceptors.request.use(
  config => {
    const middle_ware = appAuthSettings.isMiddlewareEnabled;
    if (config?.headers) {
      // If we are getting content-type from UI use it as is otherwise set to application/json
      config.headers['Content-Type'] = config.headers['Content-Type'] ?? 'application/json';
      if (middle_ware) {
        config.headers['Authorization'] = NESTED_API_KEY;
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

const API_KEY = process.env.NEXT_PUBLIC_OPERATION_API_KEY;

let OPERATION_API_URL = process.env.NEXT_PUBLIC_OPERATION_API_URL;

// Operations API client
const operationHttpClient = axios.create({
    baseURL: OPERATION_API_URL
});

operationHttpClient.interceptors.request.use(
  config => {
      if (config?.headers) {
          config.headers['Authorization'] = API_KEY;
          config.headers['Content-Type'] = 'application/json';
      }
      return config;
  },
  error => {
      Promise.reject(error);
});

export { httpClient, operationHttpClient };

export const handleResponse = (response: AxiosResponse<any>) => response.data;

export const handleError = (error: Error) => {
    console.error(error);
    throw error;
};

export const handleCustomError = (error: any) => error.response as AxiosResponse<any, any>;